<template>
  <router-view
    @back="onBack"
  />
</template>

<script>
/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 내역 (탭)
 */
export default {
  name: 'ProgramsMyApply',

  props: {
    /**
     * ACC지원프로그램 관리번호 (공모전 고유번호)
     */
    accPgmMngNo: {
      type: Number,
      required: true,
    },
  },

  methods: {
    /**
     * Back 이벤트 처리
     */
    onBack() {
      this.$router.go(-1);
      // 공모 지원하기 목록 (해당 탭)으로 이동
      /*
      this.$router.replace({
        name: 'ProgramsApplicationListTab',
        params: {
          accPgmMngNoString: `${this.accPgmMngNo}`,
        },
      });
      */
    },
  },
};
</script>
